import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Contract } from "../components/model/contract.model";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
})
export class ContractComponent implements OnInit {
  contract: Contract;

  constructor(private router: Router) {
    if (this.router.getCurrentNavigation().extras !== null) {
      const contract = this.router.getCurrentNavigation().extras.state.data;
      this.contract = contract;
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }
  ngOnInit() {}

  backToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  translate(key: String): String {
    switch (key) {
      case "basic":
        return "Basic Package";
      case "preventive":
        return "Preventive health services";
      case "physio":
        return "Physiotherapy";
      case "operation":
        return "Operational interventions";
      case "posthumous":
        return "Posthumous insurance";
      default:
        return "";
    }
  }
}
