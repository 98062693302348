export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCPXQu976wz3q65N5mlqTongRsPNO2oyiI",
    authDomain: "iagent-client-app.firebaseapp.com",
    projectId: "iagent-client-app",
    storageBucket: "iagent-client-app.appspot.com",
    messagingSenderId: "301530669372",
    appId: "1:301530669372:web:d724f70f462b0d5a993e39",
    measurementId: "G-WLN02YJY08",
  },
};
