import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { Contract } from "../components/model/contract.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  contracts: Array<Contract> = [];
  ngOnInit() {
    // setInterval(() => {
    //   this.load();
    // }, 1000);
    this.load();
  }

  load() {
    var data = [];
    this.firestore
      .collectionGroup("contracts")
      .valueChanges()
      .subscribe((data) => {
        if (this.contracts && this.contracts.length != data.length) {
          this.contracts = data as Array<Contract>;
        }
        // this.contracts = data as Array<Contract>;
        // console.log(this.contracts);
      });
  }

  openContract(contract: Contract) {
    this.router.navigate(["contract"], { state: { data: contract } });
  }
}
