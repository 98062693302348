import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private firestore: AngularFirestore) {}

  canActivate(): boolean {
    if (
      localStorage.getItem("isLogedin") !== undefined &&
      localStorage.getItem("isLogedin") === "TRUE"
    ) {
      return true;
    }

    this.router.navigateByUrl("");
    return false;
  }
}
