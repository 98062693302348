// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCPXQu976wz3q65N5mlqTongRsPNO2oyiI",
    authDomain: "iagent-client-app.firebaseapp.com",
    projectId: "iagent-client-app",
    storageBucket: "iagent-client-app.appspot.com",
    messagingSenderId: "301530669372",
    appId: "1:301530669372:web:d724f70f462b0d5a993e39",
    measurementId: "G-WLN02YJY08",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
